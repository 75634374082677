$button-box-shadow: $box-shadow;
$button-inset-box-shadow: inset 0 2px 3px rgba(50, 50, 93, .11);
$button-hover-box-shadow: 0 6px 10px rgba(50, 50, 93, .11),
0 1px 3px rgba(0, 0, 0, .08);
$button-active-box-shadow: inset 0 2px 3px rgba(50, 50, 93, .11),
0 4px 6px rgba(50, 50, 93, .11),
0 1px 3px rgba(0, 0, 0, .08);
@mixin make-button-pressable {
    box-shadow: $button-box-shadow;
    &:hover,
    &:focus {
        transform: translateY(-1px);
        box-shadow: $button-hover-box-shadow;
        text-decoration: none;
    }
    &:active {
        transform: translateY(1px);
        box-shadow: $button-active-box-shadow;
        text-decoration: none;
    }
    &.active {
        box-shadow: $button-active-box-shadow;
        text-decoration: none;
    }
}

@mixin remove-button-pressable {
    &:hover,
    &:active {
        transform: none;
        box-shadow: none;
        text-decoration: none;
    }
    &:active,
    &.active {
        box-shadow: $button-inset-box-shadow;
    }
}