@function pow($number, $exponent) {
    $value: 1;
    @if $exponent>0 {
        @for $i from 1 through $exponent {
            $value: $value * $number;
        }
    }
    @return $value;
}

@function colorLuminance($color) {
    $colors: ( 'red': red($color), 'green': green($color), 'blue': blue($color));
    @each $name,
    $value in $colors {
        $adjusted: 0;
        $value: $value / 255;
        @if $value < 0.03928 {
            $value: $value / 12.92;
        }
        @else {
            $value: ($value + .055) / 1.055;
            $value: pow($value, 2.4);
        }
        $colors: map-merge($colors, ($name: $value));
    }
    @return (map-get($colors, 'red') * .2126)+(map-get($colors, 'green') * .7152)+(map-get($colors, 'blue') * .0722);
}

@function findColorInvert($color) {
    @if (colorLuminance($color) > 0.55) {
        @return rgba(#000, 0.7)
    }
    @else {
        @return #fff
    }
}