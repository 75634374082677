#{$pfx}list {
    width: 100%;
    margin-bottom: $spacer-1;
    border-top: 1px solid #efefef;
    &__item {
        border-bottom: 1px solid #efefef;
        background: #fff;
        >* {
            padding: 15px 20px;
            display: flex;
            color: lighten($text-color, 15%);
            line-height: 13px;
            text-decoration: none;
            >* {
                flex: auto;
                padding-left: 20px;
            }
            &:hover {
                text-decoration: none;
                color: $text-color;
                background: $body-bg;
                #{$pfx}list__item__icon {
                    color: $text-color;
                }
            }
        }
        &__icon {
            color: lighten($text-color, 35%);
            flex: 0;
        }
    }
    &--borderless {
        border-top: none;
        #{$pfx}list__item:last-child {
            border-bottom: none;
        }
    }
}