//
// Variables
// --------------------------------------------------
//== Colors
//
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$color-primary: #648bfd!default; // #337ab7
$color-success: #3ecf8e !default;
$color-info: #6ed8da !default;
$color-warning: #ffb24d !default;
$color-danger: #f96f66 !default;
$color-border: rgba(0, 0, 0, 0.1) !default;
$theme-colors: () !default;
$theme-colors: map-merge( ( "primary": $color-primary, "success": $color-success, "info": $color-info, "warning": $color-warning, "danger": $color-danger, "light": $gray-light, "dark": $gray-dark), $theme-colors);
//== Typography
$font-family-base: -apple-system,
"BlinkMacSystemFont",
"San Francisco",
"Roboto",
"Segoe UI",
"Helvetica Neue",
sans-serif;
$font-size-base: 14px;
$line-height-base: 2rem; // 20/14
$font-weight-base: 400;
$font-weight-bold: 600;
$text-color: $gray-dark;
$body-bg: #f6f9fc;
$h1-font-size: $font-size-base * 2 !default;
$h2-font-size: $font-size-base * 1.75 !default;
$h3-font-size: $font-size-base * 1.5!default;
$h4-font-size: $font-size-base * 1.25 !default;
$h5-font-size: $font-size-base * 1 !default;
$h6-font-size: $font-size-base !default;
//== Design
//
$border:1px solid $color-border;
$box-shadow: 0 0 0 1px rgba(63, 63, 68, .1),
0 1px 3px 0 rgba(63, 63, 68, .1);
$input-box-shadow: 0 1px 3px 0 #e6ebf1;
$border-radius:3px;
$border-width:1px;
$spacer: 10px;
$spacer-0: $spacer / 2;
$spacer-1: $spacer;
$spacer-2: $spacer * 1.5;
$spacer-3: $spacer * 2;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( ( 0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: $spacer, 4: ($spacer * 1.5), 5: ($spacer * 3)), $spacers);
$btn-transition: color .15s ease-in-out,
background-color .15s ease-in-out,
border-color .15s ease-in-out,
box-shadow .15s ease-in-out !default;
$input-btn-focus-width: .2rem !default;
//== Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-prefers-reduced-motion-media-query: true !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;
$enable-validation-icons: true !default;
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;
// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;
// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;
// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grid-breakpoints: map-merge( ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px), $grid-breakpoints);
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);
// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$container-max-widths: map-merge( ( sm: 540px, md: 720px, lg: 960px, xl: 1140px), $container-max-widths);
@include _assert-ascending($container-max-widths, "$container-max-widths");
// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;
//== Heading
$headings-font-family: $font-family-base;
$headings-font-weight: 500;
$headings-margin-bottom: $spacer;
$headings-line-height: 1.55 !default;
$headings-color: inherit !default;
$link-color:$color-primary;