#{$pfx}tab {
    $ve: &;
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    box-shadow: inset 0 -1px 0 0 $color-border;
    flex: auto;
    &__item {
        flex: 0 0 auto;
        a {
            display: inline-block;
            font-size: 14px;
            line-height: 36px;
            text-align: center;
            cursor: pointer;
            position: relative;
            white-space: nowrap;
            color: lighten($text-color, 15%);
            padding: 3px 16px 0px;
            border-bottom: 3px solid transparent;
            text-decoration: none;
            &:hover {
                outline: none;
                color: $text-color;
                border-color: lighten($color-primary, 20%);
                text-decoration: none;
            }
        }
        &--active,
        &.active,
        &:first-child:last-child {
            a {
                &:hover {
                    border-color: $color-primary;
                }
                color: $text-color;
                cursor: default;
                text-decoration: none;
                border-color: $color-primary;
            }
        }
    }
    &--block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        #{$ve}__item {
            flex: auto;
            a {
                display: block;
            }
        }
    }
}