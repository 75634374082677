//
// Scaffolding
// --------------------------------------------------
// * {
//     box-sizing: border-box;
// }
// html {
//     height: 100%;
//     font-size: 62.5%;
//     text-size-adjust: 100%;
// }
// body {
//     font-family: $font-family-base;
//     font-size: $font-size-base;
//     line-height: $line-height-base;
//     font-weight: $font-weight-base;
//     color: $text-color;
//     height: 100%;
//     background-color: $body-bg;
// }
#{$pfx}header {
    margin-bottom: 30px;
    h1 {
        font-size: 56px;
        line-height: 1em;
    }
}

#{$pfx}footer {
    text-align: center;
    color: $gray-light;
    font-size: 1.3rem;
    padding: $spacer;
}

xmp {
    margin: 0px;
    font-size: 1.5rem;
}