#{$pfx}form {
    &__row {
        margin-bottom: $spacer-1;
        padding: $spacer-1 0;
        display: flex;
        >* {
            @include clearfix();
        }
        #{$pfx}label {
            text-align: right
        }
    }
}