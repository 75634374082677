#{$pfx}input-group {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    box-shadow: $input-box-shadow;
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
    &__addon,
    #{$pfx}label {
        background: #fff;
        border-radius: $border-radius;
        height: 32px;
        line-height: 32px;
        padding: 0 11px;
        border: $border;
        margin-bottom: 0px;
        font-weight: 400;
    }
    #{$pfx}btn,
    a#{$pfx}btn,
    a:not([href]):not([tabindex])#{$pfx}btn {
        @include remove-button-pressable;
    }
    &__addon,
    #{$pfx}input,
    #{$pfx}btn,
    a#{$pfx}btn,
    a:not([href]):not([tabindex])#{$pfx}btn {
        box-shadow: none;
        box-sizing: border-box;
        margin-left: -1px;
        &:first-child {
            border-left-width: 1px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        &:last-child {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &:not(:first-child):not(:last-child) {
            border-radius: 0;
        }
    }
    &--sm {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 24px;
        }
        #{$pfx}input,
        {
            padding: 1px 6px;
            font-size: 12px;
            height: 24px;
        }
        #{$pfx}input-group__addon,
        #{$pfx}label {
            line-height: 24px;
            height: 24px;
            padding: 0 10px;
            font-size: 12px;
        }
    }
    &--md {}
    &--lg {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 40px;
        }
        #{$pfx}input {
            padding: 9px 13px;
            font-size: 18px;
            height: 40px;
        }
        #{$pfx}input-group__addon,
        #{$pfx}label {
            line-height: 40px;
            height: 40px;
            padding: 0 13px;
            font-size: 18px;
        }
    }
    &--hg {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 48px;
        }
        #{$pfx}input {
            padding: 13px 13px;
            font-size: 19px;
            height: 48px;
        }
        #{$pfx}input-group__addon,
        #{$pfx}label {
            line-height: 48px;
            height: 48px;
            padding: 0 16px;
            font-size: 19px;
        }
    }
}