#{$pfx}h1,
#{$pfx}h2,
#{$pfx}h3,
#{$pfx}h4,
#{$pfx}h5,
#{$pfx}h6 {
    margin-bottom: $headings-margin-bottom;
    margin-top: 0px;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;
    color: $headings-color;
    display: block;
}

#{$pfx}h1 {
    font-size: $h1-font-size;
}

#{$pfx}h2 {
    font-size: $h2-font-size;
}

#{$pfx}h3 {
    font-size: $h3-font-size;
}

#{$pfx}h4 {
    font-size: $h4-font-size;
}

#{$pfx}h5 {
    font-size: $h5-font-size;
}

#{$pfx}h6 {
    font-size: $h6-font-size;
}

#{$pfx}pre {
    background: none;
    border: none;
    line-height: 13px;
    font-size: 10px;
    margin: 0px;
}

#{$pfx}hr {
    border: none;
    border-bottom: $border;
    margin: 10px 0px;
    background: none;
}

#{$pfx}p {
    margin: 0 0 10px;
    &:last-child {
        margin: 0px;
    }
}

#{$pfx}a,
a[class^=#{$pfxx}a] {
    text-decoration: none;
    color: $link-color;
    &:hover,
    &:active {
        text-decoration: none;
    }
}

#{$pfx}help {
    font-size: 12px;
}

#{$pfx}text-primary {
    color: $color-primary
}

#{$pfx}text-success {
    color: $color-success
}

#{$pfx}text-info {
    color: $color-info
}

#{$pfx}text-warning {
    color: $color-warning
}

#{$pfx}text-danger {
    color: $color-danger
}