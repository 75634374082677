$input-border:$border;
$input-border-radius: $border-radius;
$input-text-color: $text-color;
$input-font-size: $font-size-base;
$input-active-border-color:$color-primary;
#{$pfx}label {
    font-weight: 500;
    margin-bottom: 0;
    display: inline-block;
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
}

#{$pfx}input {
    $ve: &;
    display: block;
    position: relative;
    padding: 5px 10px;
    background-color: #fff;
    border: $input-border;
    border-radius: $input-border-radius;
    color: $input-text-color;
    box-sizing: border-box;
    font-size: $input-font-size;
    line-height: 20px;
    min-width: 75px;
    font-weight: 300;
    vertical-align: bottom;
    height: 32px;
    min-height: auto;
    margin: 0;
    max-width: 100%;
    font-family: -apple-system, "BlinkMacSystemFont", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", sans-serif;
    transition: box-shadow .2s cubic-bezier(.64, 0, .35, 1);
    &[type=text],
    &[type=email],
    &[type=password],
    &[type=time],
    &[type=date],
    &[type=datetime],
    &[type=tel] {
        width: 100%;
        box-shadow: $input-box-shadow;
        height: 32px;
        min-height: auto;
        border: $input-border;
        border-radius: $input-border-radius;
        &:focus {
            border-color: $input-active-border-color;
            outline: none;
            box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px $input-active-border-color, $input-box-shadow;
            box-sizing: border-box;
        }
    }
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
    &:disabled {
        cursor: not-allowed;
        background-color: #F4F6F8;
        border: $input-border;
        color: #C4CDD5;
    }
    &[type=radio],
    &[type=checkbox] {
        display: inline-block;
        margin-right: 4px;
    }
    &--sm {
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=tel],
        & {
            padding: 1px 6px;
            font-size: 12px;
            height: 24px;
        }
    }
    &--md {
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=tel],
        & {
            padding: 5px 8px;
            font-size: 14px;
            height: 32px;
        }
    }
    &--lg {
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=tel],
        & {
            padding: 9px 10px;
            font-size: 18px;
            height: 40px;
        }
    }
    &--hg {
        &[type=text],
        &[type=email],
        &[type=password],
        &[type=tel],
        & {
            padding: 13px 13px;
            font-size: 19px;
            height: 48px;
        }
    }
}

textarea#{$pfx}input {
    width: 100%;
    box-shadow: $input-box-shadow;
    height: auto;
    border: $input-border;
    border-radius: $input-border-radius;
    &:focus {
        border-color: $input-active-border-color;
        outline: none;
        box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px $input-active-border-color, $input-box-shadow;
        box-sizing: border-box;
    }
}

select#{$pfx}input {
    width: 100%;
    appearance: none;
    min-width: 75px;
    box-shadow: $input-box-shadow;
    box-sizing: border-box;
    background-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='rgb(99,115,129)' d='M13 8l-3-3-3 3h6zm-.1 4L10 14.9 7.1 12h5.8z' fill-rule='evenodd'></path></svg>");
    background-size: 18px 18px;
    background-repeat: no-repeat;
    background-position: right 7px top 7px;
    padding-right: 20px;
    border: $input-border;
    border-radius: $input-border-radius;
    &:focus {
        outline: none;
        border-color: #5c6ac4;
        box-shadow: inset 0 0 0 0 transparent, 0 0 0 1px $input-active-border-color, $input-box-shadow;
    }
}

#{$pfx}checkbox {
    position: relative;
    display: inline-block;
    padding-left: 1.7em;
    padding-right: 5px;
    vertical-align: bottom;
    cursor: pointer;
    [type=checkbox] {
        position: absolute;
        opacity: 0;
    }
    i {
        position: unset;
    }
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
    i:after,
    i:before {
        content: '';
        width: calc(1em + 3px);
        height: calc(1em + 3px);
        display: block;
        box-sizing: border-box;
        border: 1px solid transparent;
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0.1em;
        background-color: #fff;
        border: $input-border;
        box-shadow: $input-box-shadow;
        border-radius: $input-border-radius;
        transition: .4s;
    }
    i:after {
        left: 0.38em;
        top: 0.2em;
        border-width: 0;
        box-shadow: none;
        width: 0.4em;
        height: 0.8em;
        transform: rotate(40deg);
        background-color: transparent;
        transition: 0s;
    }
    [type=checkbox]:checked+i:after {
        display: block;
        border: solid #fff;
        border-width: 0 0.1em 0.1em 0;
        background-color: transparent;
        border-radius: 0;
        box-shadow: none;
    }
    [type=checkbox]:checked+i:before {
        border: 1px solid $input-active-border-color;
        background-color: $input-active-border-color;
    }
}

#{$pfx}radio {
    position: relative;
    display: inline-block;
    padding-left: 1.7em;
    padding-right: 5px;
    cursor: pointer;
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
    [type=radio] {
        position: absolute;
        opacity: 0;
    }
    &--selected {
        font-weight: bold;
        color: $link-color;
    }
    i {
        position: unset;
    }
    i:after,
    i:before {
        content: '';
        width: calc(1em + 4px);
        height: calc(1em + 4px);
        display: block;
        box-sizing: border-box;
        border: 1px solid transparent;
        z-index: 0;
        position: absolute;
        left: 0;
        top: 0.1em;
        background-color: #fff;
        border-radius: 100%;
        border: $input-border;
    }
    i:after {
        transform: scale(0);
    }
    [type=radio]:checked+i:after {
        background-color: $color-primary;
        transform: scale(0.4);
        transition: .4s;
    }
    [type=radio]:checked+i:before {
        border: 1px solid $color-primary;
        background-color: #fff;
    }
}

#{$pfx}switch {
    position: relative;
    display: inline-block;
    padding-left: 45px;
    padding-right: 5px;
    cursor: pointer;
    height: 22px;
    &+#{$pfx}help,
    &+#{$pfx}field__error {
        margin-top: $spacer-1;
    }
    [type=checkbox] {
        position: absolute;
        opacity: 0;
    }
    i:before {
        position: absolute;
        content: "";
        height: 24px;
        width: 40px;
        top: 0px;
        left: 0px;
        background-color: $color-border;
        border-radius: 1.2em;
        transition: background-color 0.5s ease;
    }
    i:after {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        top: 2px;
        left: 2px;
        background-color: white;
        transition: .4s;
        border-radius: 50%;
    }
    [type=checkbox]:checked+i:before {
        background-color: $color-primary;
    }
    [type=checkbox]:checked+i:after {
        transform: translateX(16px);
    }
}

#{$pfx}file,
label#{$pfx}file {
    border: $input-border;
    border-radius: $input-border-radius;
    color: $input-text-color;
    display: block;
    position: relative;
    text-align: right;
    background: #fff;
    [type=file] {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__title {
        float: left;
        padding: .4em .8em;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        position: absolute;
        left: 0;
        z-index: 0;
    }
    #{$pfx}btn,
    a#{$pfx}btn,
    a:not([href]):not([tabindex])#{$pfx}bt {
        margin: -1px;
        z-index: 100;
        position: relative;
    }
}