#{$pfx}editor {
    overflow: hidden;
    z-index: 1000;
    &__menu {
        background: #fff;
        height: 60px;
        width: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        flex-wrap: wrap;
        border-bottom: $border;
        position: fixed;
        z-index: 1000;
        >* {
            padding: 10px;
            flex-basis: 0;
            font-size: 14px;
            white-space: nowrap;
        }
        &__title {
            flex: auto;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                line-height: 1.4em;
                margin: 0px;
            }
        }
        &__action {}
        &__control {
            flex: 0;
        }
    }
    &__setting {
        background: #fff;
        width: 300px;
        border-right: $border;
        position: fixed;
        height: 100%;
        top: 0px;
        left: 0px;
        display: none;
        flex-direction: column;
        z-index: 1000;
        &.active {
            display: block;
        }
        &__header {
            flex: 0;
            display: flex;
            background: #fff;
            border-bottom: $border;
            font-size: 14px;
            line-height: 28px;
            &__action {
                padding: 10px;
                cursor: pointer;
                color: $text-color;
            }
            &__title {
                padding: 10px;
                flex: auto;
                cursor: pointer;
                color: $text-color;
            }
        }
        &__content {
            flex: auto;
            background: #f6f9fc;
            overflow: scroll;
            margin-bottom: 63px;
            height: 100%;
            &__section {
                padding: 15px;
                &:last-child {
                    margin-bottom: 111px;
                }
            }
        }
        &__footer {
            flex: 0;
            background: #fff;
            padding: 15px;
            border-top: $border;
            position: absolute;
            bottom: 0px;
            width: 100%;
            box-sizing: border-box;
            z-index: 1000;
        }
        #{$pfx}tab {
            background: #fff;
            &__item {
                >a {
                    line-height: 48px;
                }
            }
        }
    }
    &__content {
        overflow: scroll;
        position: relative;
        height: 100%;
    }
    &__setting+&__content {
        margin-left: 300px;
    }
    &__menu+&__content,
    &__menu+&__setting,
    &__menu+&__setting+&__content,
    &--topmenu &__content,
    &--topmenu &__setting {
        top: 60px;
        height: calc(100% - 60px);
    }
}