#{$pfx}card {
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    padding: 0px;
    margin-bottom: 15px;
    background: #fff;
    min-height: 30px;
    overflow: visible;
    &#{$pfx}col {
        margin-left: 15px;
        margin-right: 15px;
    }
    &__header {
        border-bottom: $border;
        padding: $spacer-2;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:last-child {
                margin: 0;
            }
        }
    }
    &__image {
        >img {
            width: 100%;
        }
    }
    &__section {
        padding: $spacer-2;
        @include clearfix;
        &:last-child {
            padding: $spacer-2;
        }
        >hr {
            margin: $spacer-1 0;
        }
    }
    >hr {
        margin: 0px;
    }
    &__footer {
        border-top: $border;
        padding: $spacer-3;
    }
}