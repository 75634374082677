#{$pfx}field {
    &:not(:last-child) {
        margin-bottom: $spacer-1;
    }
    #{$pfx}label {
        margin-bottom: $spacer-0;
        display: inline-block;
    }
    #{$pfx}input,
    #{$pfx}switch,
    #{$pfx}checkbox,
    #{$pfx}radio {
        display: inline-block;
        margin-bottom: 0px;
    }
    &--inline {
        >* {
            display: inline-block;
            &#{$pfx}input {
                width: inherit;
                margin-bottom: 0;
            }
        }
    }
    &--block {
        display: flex;
        align-items: center;
        #{$pfx}label {
            margin-bottom: 0px;
        }
        >*:not(:last-child) {
            margin-right: $spacer-0;
        }
    }
    &--error {
        #{$pfx}label,
        #{$pfx}switch,
        #{$pfx}checkbox,
        #{$pfx}radio {
            color: $color-danger;
        }
        [type=text]#{$pfx}input,
        [type=email]#{$pfx}input,
        [type=tel]#{$pfx}input,
        [type=password]#{$pfx}input,
        [type=time]#{$pfx}input,
        [type=date]#{$pfx}input,
        [type=datetime]#{$pfx}input,
        select#{$pfx}input,
        textarea#{$pfx}input {
            border-color: $color-danger;
            background-color: lighten($color-danger, 25%);
            &:focus {
                outline: none;
                border-color: $color-danger;
                box-shadow: inset 0 0 0 0 transparent, 0 0 0 .1rem $color-danger, 0 0 0 1px transparent, 0 1px 0 0 rgba(22, 29, 37, .05);
            }
        }
        #{$pfx}switch {
            i:before {
                background-color: $color-danger;
            }
        }
        #{$pfx}checkbox,
        #{$pfx}radio {
            i:before {
                border-color: $color-danger;
            }
        }
    }
    &__error {
        padding: 5px 10px;
        display: block;
        background: lighten($color-danger, 25%);
        color: $color-danger;
        border-radius: $border-radius;
        margin-bottom: $spacer-1;
        font-size: 14px;
    }
}