#{$pfx}btn-group {
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    display: inline-flex;
    transition: all .15s ease;
    border-radius: .3rem;
    overflow: hidden;
    background: none;
    #{$pfx}btn,
    a#{$pfx}btn,
    a:not([href]):not([tabindex])#{$pfx}btn {
        flex: 1 1 auto;
        border-right-width: 0;
        border-radius: 0;
        box-shadow: none;
        border-right: $border;
        margin-bottom: 0;
        position: relative;
        @include remove-button-pressable;
        &:active {
            padding-bottom: 0px;
            padding-top: 1px;
        }
        >input[type=radio],
        >input[type=checkbox] {
            position: absolute;
            clip: rect(0, 0, 0, 0);
            pointer-events: none;
        }
        &:first-child {}
        &:last-child {
            border: none;
        }
    }
    &--sm {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 24px;
            height: 24px;
            font-size: 13px;
            padding: 0 8px;
        }
    }
    &--md {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 32px;
            height: 32px;
            font-size: 15px;
        }
    }
    &--lg {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 40px;
            height: 40px;
            font-size: 17px;
            padding: 0 18px;
        }
    }
    &--hg {
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            line-height: 48px;
            height: 48px;
            font-size: 19px;
            padding: 0 20px;
        }
    }
    &--block {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    &--vertical {
        display: inline-flex;
        flex-direction: column;
        #{$pfx}btn,
        a#{$pfx}btn,
        a:not([href]):not([tabindex])#{$pfx}btn {
            border-right: none;
            border-bottom: $border;
            &:last-child {
                border: none;
            }
        }
    }
}