@import "mixins/alert";
#{$pfx}alert {
    $ve: &;
    padding: 15px;
    border-radius: 3px;
    color: $text-color;
    margin-bottom: $spacer-1;
    box-shadow: 0 2px 4px 0 rgba(50, 50, 93, .1);
    position: relative;
    font-size: 14px;
    &__media {
        padding: 0px 15px 15px 0px;
        float: left;
        height: 100%;
    }
    &__close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 15px;
        cursor: pointer;
        border: none;
        background: none;
        font-size: 20px;
        &:active,
        &:focus {
            outline: none;
        }
    }
    &--dismissible {
        padding-right: 30px;
    }
    &--primary {
        background: lighten($color-primary, 25%);
        color: $color-primary;
        @include make-alert-shadow($color-primary);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: $color-primary;
        }
    }
    &--danger {
        background: lighten($color-danger, 25%);
        @include make-alert-shadow($color-danger);
        color: darken($color-danger, 10%);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: $color-danger;
        }
    }
    &--warning {
        background: lighten($color-warning, 30%);
        @include make-alert-shadow($color-warning);
        color: darken($color-warning, 10%);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: $color-warning;
        }
    }
    &--success {
        background: lighten($color-success, 35%);
        @include make-alert-shadow($color-success);
        color: darken($color-success, 10%);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: $color-success;
        }
    }
    &--info {
        background: lighten($color-info, 30%);
        @include make-alert-shadow($color-info);
        color: darken($color-info, 10%);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: $color-info;
        }
    }
    &--default {
        background: #fff;
        @include make-alert-shadow(#333);
        #{$ve}__heading,
        #{$ve}__close,
        i {
            color: findColorInvert(#fff);
        }
    }
}