$badge-padding-x: 0.4em;
$badge-padding-y: .25em;
$badge-font-size: 75%;
$badge-font-weight: $font-weight-bold;
$badge-border-radius: $border-radius;
$badge-transition: $btn-transition;
$badge-focus-width: $input-btn-focus-width;
$badge-pill-padding-x:.6em;
$badge-pill-border-radius: 10rem;
#{$pfx}badge {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    font-size: $badge-font-size;
    font-weight: $badge-font-weight;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius($badge-border-radius);
    @include transition($badge-transition);
    @at-root a#{&} {
        @include hover-focus {
            text-decoration: none;
        }
    }
    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
#{$pfx}btn #{$pfx}badge {
    position: relative;
    top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.
#{$pfx}badge-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).
@each $color,
$value in $theme-colors {
    #{$pfx}badge--#{$color} {
        @include badge-variant($value);
    }
}