#{$pfx}btn,
a#{$pfx}btn,
a:not([href]):not([tabindex])#{$pfx}btn {
    $ve: &;
    appearance: none;
    outline: none;
    border-style: none;
    color: $gray-base;
    display: inline-block;
    padding: 0 12px;
    letter-spacing: .025em;
    font-size: 14px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
    transition: all .15s ease;
    background: #fff;
    color: findColorInvert(#fff);
    flex: auto;
    text-align: center;
    margin-bottom: 0px;
    box-sizing: border-box;
    cursor: pointer;
    @include make-button-pressable;
    >* {
        color: findColorInvert(#fff);
    }
    &:active,
    &.active,
    &:focus {
        outline: none;
    }
    &:disabled {
        cursor: not-allowed;
    }
    &--loading {
        position: relative;
        color: transparent!important;
        pointer-events: none;
        &::after {
            animation: spinner-border .5s infinite linear;
            border: 2px solid #dbdbdb;
            border-radius: 290486px;
            border-right-color: transparent !important;
            border-top-color: transparent !important;
            content: "";
            display: block;
            height: 1em;
            position: relative;
            width: 1em;
            position: absolute;
            left: calc(50% - (1em / 2));
            top: calc(50% - (1em / 2));
            position: absolute!important;
        }
    }
    /*size*/
    &--sm {
        line-height: 24px;
        height: 24px;
        font-size: 13px;
        padding: 0 8px;
    }
    &--md {
        line-height: 32px;
        height: 32px;
        font-size: 14px;
    }
    &--lg {
        line-height: 40px;
        height: 40px;
        font-size: 17px;
        padding: 0 18px;
    }
    &--hg {
        line-height: 48px;
        height: 48px;
        font-size: 19px;
        padding: 0 20px;
    }
    /*state*/
    &--primary {
        background: $color-primary;
        color: findColorInvert($color-primary);
        >* {
            color: findColorInvert($color-primary);
        }
        &:after {
            border-color: findColorInvert($color-primary);
        }
        &:hover {
            background: lighten($color-primary, 2%);
        }
        &:active,
        &.active {
            background: darken($color-primary, 15%);
        }
    }
    &--danger {
        background: $color-danger;
        color: findColorInvert($color-danger);
        >* {
            color: findColorInvert($color-danger);
        }
        &:after {
            border-color: findColorInvert($color-danger);
        }
        &:hover {
            background: lighten($color-danger, 2%);
        }
        &:active,
        &.active {
            background: darken($color-danger, 15%);
        }
    }
    &--warning {
        background: $color-warning;
        color: findColorInvert($color-warning);
        >* {
            color: findColorInvert($color-warning);
        }
        &:after {
            border-color: findColorInvert($color-warning);
        }
        &:hover {
            background: lighten($color-warning, 2%);
        }
        &:active,
        &.active {
            background: darken($color-warning, 15%);
        }
    }
    &--success {
        background: $color-success;
        color: findColorInvert($color-success);
        >* {
            color: findColorInvert($color-success);
        }
        &:after {
            border-color: findColorInvert($color-success);
        }
        &:hover {
            background: lighten($color-success, 2%);
        }
        &:active,
        &.active {
            background: darken($color-success, 15%);
        }
    }
    &--info {
        background: $color-info;
        color: findColorInvert($color-info);
        >* {
            color: findColorInvert($color-info);
        }
        &:after {
            border-color: findColorInvert($color-info);
        }
        &:hover {
            background: lighten($color-info, 2%);
        }
        &:active,
        &.active {
            background: darken($color-info, 15%);
        }
    }
    &--default {
        background: #e6ebf1;
        color: findColorInvert(#e6ebf1);
        >* {
            color: findColorInvert(#e6ebf1);
        }
        &:after {
            border-color: findColorInvert(#e6ebf1);
        }
        &:active,
        &.active {
            background: darken(#e6ebf1, 15%);
        }
    }
    &--outline {}
    &--block {
        width: 100%;
    }
}