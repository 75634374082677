$table-bg: #fff;
$table-cell-padding: $spacer-1;
$table-border-width: $border-width;
$table-border-color: $color-border;
$table-cell-padding-sm: $spacer-0;
$table-striped-order: 2;
$table-hover-bg: darken($body-bg, 0.2);
$table-accent-bg: darken($body-bg, 0.2);
$table-border-radius: $border-radius;
//
// Basic Bootstrap table
//
#{$pfx}table {
    width: 100%;
    margin-bottom: 0;
    border-collapse: collapse;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.
    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }
    th {
        border-top: none;
    }
    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }
    tbody+tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }
    #{$pfx}table {
        background-color: $body-bg;
    }
}

//
// Condensed table w/ half padding
//
#{$pfx}table--sm {
    th,
    td {
        padding: $table-cell-padding-sm;
    }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.
#{$pfx}table--bordered {
    box-shadow: 0 0 0 $table-border-width $table-border-color;
    border-radius: $table-border-radius;
    th,
    td {
        border: $table-border-width solid $table-border-color;
    }
    tr:first-child th,
    tr:first-child td {
        border-top: 0px;
    }
    tr th:first-child,
    tr td:first-child {
        border-left: 0px;
    }
    tr th:last-child,
    tr td:last-child {
        border-right: 0px;
    }
    tr:last-child td {
        border-bottom: 0px;
    }
    tr:last-child td:first-child {
        border-left: 0px;
    }
    tr:last-child td:last-child {
        border-right: 0px;
    }
    thead {
        th,
        td {
            border-bottom-width: 2 * $table-border-width;
        }
    }
}

#{$pfx}table--borderless {
    th,
    td,
    thead th,
    tbody+tbody {
        border: 0;
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
#{$pfx}table--striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping
#{$pfx}table--hover {
    tbody tr {
        @include hover {
            background-color: $table-hover-bg;
        }
    }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.
#{$pfx}table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);
        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;
                -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057
                // Prevent double border on horizontal scroll due to use of `display: block;`
                >.table-bordered {
                    border: 0;
                }
            }
        }
    }
}